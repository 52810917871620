.error-container {
  padding: 16px;
}
.error-message {
  padding-top: 32px;
}

@media screen and (min-width: 1440px) {
  .error-container {
    padding: 32px 48px 0 64px;
  }
}
