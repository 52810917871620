.task-card {
  padding: 16px;
  display: flex;
  border-bottom: 1px solid var(--grey200);
  background: var(--white);
}

.layout {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 8px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

.layout.planned-task {
  background: var(--grey50);
  border-bottom: 2px dashed var(--disabled);
}

.top-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.type {
  color: var(--grey600);
  font-size: 12px;
}

.priority-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.priority {
  color: var(--pink);
  font-size: 12px;
}

.priority.completed-or-closed {
  color: var(--grey718);
}

.priority-dot {
  background: var(--pink);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.priority-dot.completed-or-closed {
  background: var(--grey718);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}

.middle-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: auto;
  gap: 16px;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.with-image {
  width: calc(100% - 85px);
}

.title {
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assignee-wrapper {
  flex: 0 1 auto;
  max-width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: var(--grey718);
  font-size: 12px;
}

.timestamp-wrapper {
  flex: 1 1 0;
  min-width: 0;
  color: var(--grey718);
  font-size: 12px;
  padding: 2px 4px;
  height: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sub-title,
.assignee,
.product-description,
.timestamp {
  color: var(--grey718);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-description {
  font-size: 14px;
}

.sub-title {
  font-size: 14px;
}

.icon {
  height: 20px;
  width: 20px;
}

.assignee-icon {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.closed-icon {
  width: 16px;
  height: 16px;
  color: var(--grey718);
}

.checkbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1px !important;
  padding-right: 16px !important;
}

.frequency-container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;
}

.frequency-text {
  text-overflow: ellipsis;
  color: var(--blue-family);
  overflow: hidden;
  font-size: 14px;
}

.frequency-icon {
  width: 20px;
  color: var(--blue-family);
}

.frequency {
  display: flex;
  align-items: center;
}

.frequency-completed-or-closed {
  color: var(--black);
}

.overdue {
  color: var(--pink);
  font-size: 12px;
}

.bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  overflow: hidden;
}

.banner {
  width: auto;
  padding: 8px;
  background: var(--grey100);
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  color: var(--grey718);
  border-radius: 4px;
}

.information {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: visible;
  flex-wrap: wrap;
}

.information-location {
  padding: 0px 4px;
  border-radius: 4px;
  border: 1px solid var(--grey200);
}

.information-bold {
  font-weight: bold;
}

.arrow-right-icon {
  width: 16px;
  height: 16px;
  color: var(--grey900);
  flex-shrink: 0;
}
