.multiselect-tasks-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.multiselect-tasks-button button {
  width: 90%;
}
