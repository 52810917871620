.container {
  background: var(--white);
  width: 100%;
  border-top: 1px solid var(--grey200);
}

.container-overflow {
  overflow-y: auto;
}

.container-spacer {
  min-height: calc(100% - 70px);
}

.keep-task,
.suggestions {
  border-top: 10px solid var(--grey150) !important;
  border-bottom: 10px solid var(--grey150) !important;
  label {
    box-sizing: border-box;
  }
  span {
    box-sizing: border-box;
  }
}

.member {
  display: flex;
  img {
    border-radius: 50%;
  }
}

.team {
  height: 4rem;
  display: flex;
}

.accordion {
  div {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }
  button {
    border-bottom: 1px solid var(--grey200);
  }
}

.last-suggestion {
  border-bottom: none !important;
}

.accordion-item {
  button {
    padding: 0 1.5rem;
    border-top: none !important;
  }
}
