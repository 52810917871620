.task-list-tabs .tabs__panel {
  padding: 0;
}

.task-list-tabs .tabs__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
}

.task-list-tabs .tabs__list {
  z-index: 2;
  background: var(--white);
}
