.button-164 {
  width: 164px;
}

.text-no-tasks {
  height: 22px;
  padding-bottom: 8px;
  text-align: center;
}

.tasks-todos:has(.tasks-empty, .tasks-loading) {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}
