.filters-container {
  padding: 24px 8px 16px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  overflow: auto hidden;
  white-space: nowrap;
  @media screen and (min-width: 1000px) {
    padding: 0px;
    margin: 24px 0px;
  }
}

.filters-container::-webkit-scrollbar {
  display: none;
}

.selectable-pill-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selectable-pill-input {
  height: 24px;
  width: 24px;
  accent-color: var(--black);
}

.dropdown-pill-desktop {
  display: none;
  @media screen and (min-width: 1000px) {
    display: block;
  }
}

.dropdown-pill-mobile {
  @media screen and (min-width: 1000px) {
    display: none;
  }
}

.backdrop {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.tooltip {
  margin-left: 4px;
}

.stack-icon-wrapper {
  border: 1px solid var(--grey900);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}

.separator {
  flex-shrink: 0;
  width: 1px;
  height: 40px;
  background-color: var(--grey200);
}
