.main-header {
  background-color: rgb(0, 62, 114);
  display: flex;
  flex-shrink: 0;
  height: 72px;
}

@media screen and (max-width: 900px) {
  .main-header {
    padding: 0 0 0 16px;
  }
}

@media screen and (min-width: 1440px) {
  .main-header {
    padding: 0 48px 0 64px;
  }
}

.main-header h1 {
  color: rgb(255, 219, 0);
  cursor: pointer;
}

.main-header .left-side,
.right-side {
  align-items: center;
  display: flex;
  flex: 1;
}

.main-header .left-side {
  justify-content: flex-start;
}

.main-header .right-side {
  justify-content: flex-end;
}

.main-header .profile-link .btn__inner {
  padding: 0px !important;
}

.profile-link:last-child {
  margin-right: 16px;
}
