.filters-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  overflow: auto hidden;
  white-space: nowrap;
  padding: 0px;
  margin: 12px 0 24px 0;
}

.filters-container::-webkit-scrollbar {
  display: none;
}
