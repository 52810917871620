.column {
  height: 100%;
  flex: 1;
}

.column:not(.active) {
  display: none;
  @media screen and (min-width: 1200px) {
    display: block;
  }
}

.wrapper {
  margin-bottom: auto;
  height: 100%;
  @media screen and (min-width: 1200px) {
    background: var(--grey100);
    overflow-y: auto;
    border: 10px solid var(--grey100);
    flex: 1;
  }
}

.title {
  display: none;
  @media screen and (min-width: 1200px) {
    display: block;
    font-size: 16px;
    font-weight: 700;
    background: var(--white);
    padding-bottom: 15px;
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}

.no-results-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--grey718);
}

.no-results-subtitle {
  font-size: 14px;
  color: var(--grey718);
  margin-bottom: 8px;
}

.loading {
  display: none;
  @media screen and (min-width: 1200px) {
    display: block;
    height: 100%;
  }
}
