.big-image {
  width: 106px;
  height: 106px;
  object-fit: contain;
}
.small-image {
  height: 40px;
  width: 40px;
  object-fit: contain;
}
